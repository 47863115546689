<template>
    <div class="container padding-container">
        <el-button class="topBtns" type="primary" icon="el-icon-plus" @click="routerChange(1)">新增{{list_name}}
        </el-button>
        <div class="border-container">
            <el-row class="search-container" type="flex" align="middle" justify="space-between">
                <el-col :lg="2" :sm="4" :xs="8">
                    <span class="list-title">{{list_name}}列表</span>
                </el-col>
                <el-col :lg="22" :sm="20" :xs="16" class="search-area">
                    <el-col :span='6' class="search-item">
                        <el-input
                            class="circular-bead-select"
                            v-model="searchForm.name"
                            clearable
                            placeholder="请选择名称"
                            @change="pageChange(1)">
                        </el-input>
                    </el-col>
                    <el-col :span='6' class="search-item">
                        <el-select
                            class="circular-bead-select"
                            v-model="searchForm.status"
                            clearable
                            placeholder="请选择状态"
                            @change="pageChange(1)">
                            <el-option v-for="item in statusList" :key="item.value" :label="item.name"
                                :value="item.value" />
                        </el-select>
                    </el-col>
                </el-col>
            </el-row>
            <div class="table-container">
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" size="medium"
                    @selection-change="handleSelectionChange"
                    :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                    :cell-style="{height: '50px',padding: '5px 0'}" header-align="center" :row-key="(row) => row.id">
                    <el-table-column type="selection" width="60" :reserve-selection="true" align="center">
                    </el-table-column>
                    <el-table-column :label="list_name + '名称'" min-width="150" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <div class="hover-text-colourful fz-bold" @click="routerChange(2, scope.row)"
                                style="padding-left: 5px">
                                {{scope.row.name}}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="价格" min-width="100" :show-overflow-tooltip="true" align="center">
                        <span slot-scope="scope" class="text-colourful">
                            <template v-if='scope.row.price'>￥{{scope.row.price | numFixed}}</template>
                            <template v-else>免费</template>
                        </span>
                    </el-table-column>
                    <!-- <el-table-column label="订阅量" min-width="120" align="center">
                    <template slot-scope="scope">
                        {{scope.row.count}}
                    </template>
                </el-table-column> -->
                    <el-table-column label="状态" min-width="120" align="center">
                        <template slot-scope="scope">
                            <i class="iconfont icon-point" :class="scope.row.status | courseStatusFilter('class')" />
                            {{scope.row.status | courseStatusFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.create_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="上架时间" min-width="155" align="center">
                        <template slot-scope="scope">
                            {{scope.row.publish_time | timeFilter}}
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" min-width="150" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" class="icon-btn" @click="handleCopy(scope.row.id)">
                                <el-tooltip placement="top" content="复制课程">
                                    <i class="iconfont icon-copy" />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="routerChange(2, scope.row)">
                                <el-tooltip placement="top" content="课程详情">
                                    <i class="iconfont icon-check" />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="routerChange(3, scope.row)">
                                <el-tooltip placement="top" content="课程编辑">
                                    <i class="iconfont icon-edit" />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="handleStatusChange(scope.row)">
                                <el-tooltip placement="top" :content="scope.row.status === 2 ? '下架课程' : '上架课程'">
                                    <i class="iconfont icon-sold-out" v-if="scope.row.status === 2" />
                                    <i class="iconfont icon-putaway" v-else />
                                </el-tooltip>
                            </el-button>
                            <el-button type="text" class="icon-btn" @click="handleDelete(scope.row.id)">
                                <el-tooltip placement="top" content="删除课程">
                                    <i class="iconfont icon-delete" />
                                </el-tooltip>
                            </el-button>
                        </template>
                    </el-table-column>
                    <div class="" slot="empty">
                        <no-data></no-data>
                    </div>
                </el-table>
            </div>
            <div class="page-container">
                <el-pagination layout="total, sizes, prev, pager, next" background @size-change="pageSizeChange"
                    @current-change="pageChange" :current-page="currentPage" :page-sizes="[total, 10, 15, 20, 25]"
                    :total="total" :page-size="pageSize">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import Core from '@/core';

const USER_TYPE = Core.Const.USER_TYPE;

export default {
    components: {
        NoData: () => import('@/components/Empty.vue')
    },
    props: {},
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE,
            type: '',
            list_name: '',

            total: 0,
            currentPage: 1,
            pageSize: 10,

            searchForm: {
                group_id: '',
                status: '',
                name: '',
            },
            statusList: Core.Const.COURSE.STATUS_LIST,

            tableData: [],

            multipleSelection: []
        };
    },
    watch: {
        $route: {
            deep: true,
            handler(newRoute) {
                this.type = newRoute.meta.type;
                this.list_name = newRoute.meta.title;
                this.currPage = 1;

                this.searchForm = {
                    group_id: '',
                    status: '',
                    name: '',
                }
                this.getTableData();
            }
        }
    },
    mounted() {
        this.type = this.$route.meta.type;
        this.list_name = this.$route.meta.title;
        this.getTableData();
    },
    methods: {
        pageChange(page) {
            this.currentPage = page;
            this.getTableData();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getTableData();
        },
        routerChange(key, item) {
            switch (key) {
                case 1: // 新建
                    this.$router.push({
                        path: '/online-course/course-edit',
                        query: {
                            type: this.type
                        }
                    });
                    break;
                case 2: // 详情
                    this.$router.push({
                        path: '/online-course/course-detail',
                        query: {
                            id: item.id
                        }
                    });
                    break;
                case 3: // 编辑
                    this.$router.push({
                        path: '/online-course/course-edit',
                        query: {
                            type: this.type,
                            id: item.id
                        }
                    });
                    break;
            }
        },
        getTableData() {
            Core.Api.Course.list(
                this.currentPage,
                this.pageSize,
                this.type,
                this.searchForm.group_id,
                this.searchForm.status || -1,
                '',
                this.searchForm.name,
            ).then((res) => {
                console.log('getTableData res:', res);
                this.total = res.count;
                this.tableData = res.list;
            });
        },
        handleSelectionChange(val) {
            this.multipleSelection = val.map((item) => item.id);
            console.log(this.multipleSelection);
        },

        handleStatusChange(item) {
            this.$confirm(
                `确定要${item.status ? '下架' : '上架'}这个课程吗`,
                '提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(() => {
                Core.Api.Course.updateStatus(item.id)
                    .then((res) => {
                        this.$message.success(
                            `${item.status ? '下架' : '上架'}成功!`
                        );
                        this.pageChange(1);
                    })
                    .catch((err) => {
                        console.log('err:', err);
                        this.$message.error(
                            `${item.status ? '下架' : '上架'}成功!`
                        );
                        this.pageChange(1);
                    });
            });
        },
        handleDelete(id) {
            this.$confirm('确定要删除这个课程吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Course.delete(id).then((res) => {
                    this.$message.success('删除成功!');
                    this.pageChange(1);
                });
            });
        },
        handleCopy(id) {
            Core.Api.Course.copy(id).then((res) => {
                this.$message.success('复制成功');
                this.pageChange(1);
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.container {
    @import '@/common/styles/table.scss';
}
</style>
